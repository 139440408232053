<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        width="400"
        scrollable
    >
      <form @submit.prevent="save">
        <v-card :loading="btnLoading">

          <v-card-title>
            {{ title }}
            <v-spacer/>
            <v-icon @click="close">mdi-close</v-icon>
          </v-card-title>

          <v-divider/>

          <v-card-text class="pa-4">

            <v-text-field label="Valeur *"
                          :error-messages="errors.value"
                          v-model="form.value">
            </v-text-field>

          </v-card-text>

          <v-divider/>

          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary"
                   :disabled="btnLoading"
                   :loading="btnLoading"
                   depressed
                   type="submit">
              <v-icon left>mdi-content-save</v-icon>
              Sauvgarder
            </v-btn>
          </v-card-actions>

        </v-card>
      </form>

    </v-dialog>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      dialog: false,
      btnLoading: false,
      errors: {},
      form: {},
      title: null
    }
  },
  methods: {
    open(item) {
      this.errors = {}
      this.form = {}
      if (item) {
        this.title = 'Modifier un detail monétaire'
        this.form = item
      } else {
        this.title = 'Ajouter un detail monétaire'
      }

      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    save() {
      this.errors = {}
      this.btnLoading = true

      let methode = this.form.id ? 'update' : 'store'

      HTTP.post('/v1/money/' + methode, this.form).then(() => {
        this.btnLoading = false
        this.$successMessage = 'Cet élément a été enregistré avec succès.'

        if (this.form.id) {
          this.close()
        }
        this.$emit('refresh')
        this.form = {}
      }).catch(err => {
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur s'est produite veuillez corriger."
        this.btnLoading = false
        console.log(err)
      })
    },
  },
}
</script>

<style>
</style>