<template>
  <div>

    <v-overlay :value="overlay"
               absolute
               color="white"
               opacity="0.5">
      <v-progress-circular
          indeterminate
          size="50"
          color="primary"
      ></v-progress-circular>
    </v-overlay>

    <v-card-title class="text--primary">
      Details monétaire
      <v-spacer/>

      <v-btn depressed
             small
             fab
             exact
             to="/recoveries">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

    </v-card-title>

    <v-divider/>

    <div class="d-flex justify-space-between pa-3">
      <v-spacer/>
      <v-btn @click="add"
             depressed
             color="gifty"
             dark>
        <v-icon left>mdi-plus</v-icon>
        Ajouter
      </v-btn>
    </div>

    <v-divider/>

    <v-row v-if="isLoading" class="pa-4">
      <v-col cols="6" lg="3"
             v-for="el in 4" :key="el">
        <v-card flat>
          <v-card-text>
            <v-skeleton-loader type="article"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div v-else>

      <v-row v-if="items.length" class="pa-4">
        <v-col cols="6" lg="3"
               v-for="(item,i) in items" :key="i">
          <v-card flat color="grey lighten-4">
            <v-card-text class="text-center">

              <v-avatar color="gifty">
                <v-icon dark>mdi-cash</v-icon>
              </v-avatar>

              <span class="mt-4 d-block font-weight-medium">
                {{ item.value }} DZD
              </span>

            </v-card-text>

            <v-divider/>

            <v-card-actions>
              <v-spacer/>
              <v-btn icon @click="update(item)">
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-col>
      </v-row>

      <div v-else>
        <NoResults @reload="getItems"/>
      </div>

    </div>

    <FormDialog ref="formDialog" @refresh="getItems"/>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import FormDialog from "./FormDialog";

export default {
  components: {FormDialog, NoResults},
  data() {
    return {
      isLoading: false,
      overlay: false,
      id: null,
      items: []
    };
  },
  methods: {
    getItems() {
      this.isLoading = true
      HTTP.get('/v1/money').then(res => {
        this.isLoading = false
        this.items = res.data.data
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    add() {
      this.$refs.formDialog.open()
    },
    update(item) {
      this.$refs.formDialog.open(item)
    },
  },
  created() {
    this.getItems();
  },
};
</script>

<style scoped></style>
