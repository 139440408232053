<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        width="400"
        scrollable
    >

      <v-card>

        <v-card-title>
          Récupérateurs
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">

          <v-list nav>
            <v-list-item
                v-for="(recuperator, i) in recuperators"
                :key="i">
              <v-list-item-avatar>
                <v-img :src="require('@/assets/avatar.png')"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="recuperator.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list>

        </v-card-text>

      </v-card>

    </v-dialog>

  </div>
</template>

<script>

export default {
  data() {
    return {
      dialog: false,
      recuperators: []
    }
  },
  methods: {
    open(recuperators) {
      this.recuperators = recuperators
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
  },
}
</script>

<style>
</style>