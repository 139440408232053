<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        width="500"
        scrollable
    >
      <form @submit.prevent="save">
        <v-card :loading="btnLoading">

          <v-card-title>
            {{ title }}
            <v-spacer/>
            <v-icon @click="close">mdi-close</v-icon>
          </v-card-title>

          <v-divider/>

          <v-card-text class="pa-4">

            <v-alert v-if="messages.length > 0" type="error" text prominent>
              <ul>
                <li v-for="(message,i) in messages" :key="i">{{ message }}</li>
              </ul>
            </v-alert>

            <v-text-field label="Nom *"
                          :error-messages="errors.name"
                          v-model="form.name">
            </v-text-field>

            <v-textarea rows="1"
                        :error-messages="errors.description"
                        label="Description"
                        persistent-hint
                        v-model="form.description"/>

            <v-select label="Récupérateurs *"
                      multiple
                      small-chips
                      deletable-chips
                      :loading="isLoading"
                      :disabled="isLoading"
                      :items="recuperators"
                      item-text="name"
                      item-value="id"
                      :error-messages="errors.recuperator_ids"
                      v-model="form.recuperator_ids">
            </v-select>

          </v-card-text>

          <v-divider/>

          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary"
                   :disabled="btnLoading"
                   :loading="btnLoading"
                   depressed
                   type="submit">
              <v-icon left>mdi-content-save</v-icon>
              Sauvgarder
            </v-btn>
          </v-card-actions>

        </v-card>
      </form>

    </v-dialog>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      dialog: false,
      btnLoading: false,
      isLoading: false,
      errors: {},
      form: {
        recuperator_ids: []
      },
      title: null,
      recuperators: [],

      messages: [],
    }
  },
  methods: {
    open(item) {

      if (this.recuperators.length === 0) {
        this.getRecuperators()
      }

      this.errors = {}
      this.messages = []
      if (item) {
        this.title = 'Modifier une caisse'
        this.form = item
        this.form.recuperator_ids = item.recuperators.map(el => el.id)
      } else {
        this.title = 'Ajouter une caisse'
      }

      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    save() {
      this.messages = []
      this.errors = {}
      this.btnLoading = true

      let methode = this.form.id ? 'update' : 'store'

      HTTP.post('/v1/caisses/' + methode, this.form).then(() => {
        this.btnLoading = false
        this.$successMessage = 'Cet élément a été enregistré avec succès.'
        if (this.form.id) {
          this.close()
        }
        this.$emit('refresh')
        this.form = {}
      }).catch(err => {

        if (err.response.data.messages) {
          this.messages = err.response.data.messages
        }

        if (err.response.data.errors) {
          this.errors = err.response.data.errors
        }

        this.$errorMessage = "Une erreur s'est produite veuillez corriger."
        this.btnLoading = false

        console.log(err)
      })
    },
    getRecuperators() {
      this.isLoading = true
      HTTP.get('/v1/recoveries/recuperators').then(res => {
        this.isLoading = false
        this.recuperators = res.data.data
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
  },
}
</script>

<style>
</style>