<template>
  <div>
    <v-container fluid>

      <div class="main">

        <v-card flat>
          <v-card-text class="pa-0">
            <v-row no-gutters>
              <v-col cols="12" lg="3" style="border-right: 1px solid #eaeaea">
                <v-card flat>
                  <v-card-text class="pa-0">

                    <v-list nav>

                      <v-list-item-group v-model="model"
                                         :value="model"
                                         mandatory
                                         color="primary">

                        <v-list-item v-for="(item, i) in items"
                                     :key="i"
                                     :value="item.value">
                          <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title v-text="item.text"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                      </v-list-item-group>

                    </v-list>


                  </v-card-text>

                </v-card>
              </v-col>
              <v-col cols="12" lg="9">
                <RaisonList v-show="model==='a'" ref="raisonList"/>
                <MoneyList v-show="model==='b'" ref="moneyList"/>
                <CaisseList v-show="model==='c'" ref="CaisseList"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

      </div>

    </v-container>
  </div>
</template>

<script>

import RaisonList from "./raisons/RaisonList";
import CaisseList from "./caisses/CaisseList";
import MoneyList from "./money/MoneyList";

export default {
  components: {RaisonList, CaisseList, MoneyList},
  data() {
    return {
      items: [
        {text: 'Raisons', icon: 'mdi-clipboard-list-outline', value: 'a'},
        {text: 'Details monétaire', icon: 'mdi-cash-100', value: 'b'},
        {text: 'Caisses', icon: 'mdi-file-table-box-outline', value: 'c'},
      ],
      model: 'a',
    }
  },
  watch: {
    model(val) {
      switch (val) {
        case 'a':
          return this.$refs.raisonList.getItems();
        case 'b':
          return this.$refs.moneyList.getItems();
        case 'c':
          return this.$refs.CaisseList.getItems();
      }
    }
  },
};
</script>

<style scoped></style>
