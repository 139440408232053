<template>
  <div>

    <v-card-title class="text--primary">
      Raisons
      <v-spacer/>

      <v-btn depressed
             small
             fab
             exact
             to="/recoveries">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

    </v-card-title>

    <v-divider/>

    <div class="d-flex justify-space-between pa-3">
      <v-spacer/>
      <v-btn @click="add"
             depressed
             color="gifty"
             dark>
        <v-icon left>mdi-plus</v-icon>
        Ajouter
      </v-btn>
    </div>

    <v-divider/>

    <v-card flat>

      <v-overlay :value="overlay"
                 absolute
                 color="white"
                 opacity="0.5">
        <v-progress-circular
            indeterminate
            size="50"
            color="primary"
        ></v-progress-circular>
      </v-overlay>

      <v-card-text v-if="isLoading">
        <v-skeleton-loader type="table"/>
      </v-card-text>

      <div v-else>

        <v-card-text class="pa-0">

          <v-simple-table v-if="items.length">
            <template v-slot:default>
              <thead>
              <tr>
                <th>Nom</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,i) in items" :key="i">
                <td>{{ item.name }}</td>
                <td>{{ item.description }}</td>
                <td>
                  <v-btn icon @click="update(item)">
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn icon @click="remove(item.id)">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div v-else>
            <NoResults @reload="getItems"/>
          </div>

        </v-card-text>

      </div>

    </v-card>

    <FormDialog ref="formDialog" @refresh="getItems"/>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import FormDialog from "./FormDialog";

export default {
  components: {FormDialog, NoResults},
  data() {
    return {
      isLoading: false,
      overlay: false,
      id: null,
      items: []
    };
  },
  methods: {
    getItems() {
      this.isLoading = true
      HTTP.get('/v1/recovery-raisons').then(res => {
        this.isLoading = false
        this.items = res.data.data
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    add() {
      this.$refs.formDialog.open()
    },
    update(item) {
      this.$refs.formDialog.open(item)
    },
    remove(id) {
      this.id = id
      this.$confirm_dialog = true
    },
    deleteItem() {
      this.overlay = true
      let data = {
        id: this.id
      }
      HTTP.post('/v1/recovery-raisons/delete', data).then(() => {
        this.overlay = false
        let i = this.items.findIndex(el => el.id === this.id)
        this.$delete(this.items, i)
        this.id = null
      }).catch(err => {
        this.overlay = false
        console.log(err)
      })
    }
  },
  created() {
    this.getItems();
  },
  watch: {
    $is_confirm: function (el) {
      if (el) {
        this.deleteItem()
        this.$is_confirm = false
      }
    },
  },
};
</script>

<style scoped></style>
